.user {
    display: flex;
    gap: 16px;
    align-items: center;
}

.item {
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    font-size: 14px;
}

.balance {
    color: rgba(255,255,255,.48);
    font-size: 12px;
}

.logout {
    width: 44px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
