.root {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid rgba(255,255,255,0.16);
    padding: 10px 16px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: rgba(255,255,255,0.64);
}

.body {
    display: flex;
    align-items: center;
}

.input {
    flex-grow: 1;
    font-size: 24px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: none;
    color: #fff;
    font-weight: 700;
    transition: 0.25s ease;

    &::placeholder {
        opacity: 1;
        color: rgba(255,255,255,0.24);
    }

    &:disabled {
        opacity: 0.1;
        cursor: not-allowed;
    }
}
