.loader {
    color: inherit;
    display: block;
    width: 16px;
    height: 16px;
    animation: rotate 1s linear infinite;

    @keyframes rotate {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
