.root {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 500px;
    padding: 28px 40px 40px;
    box-sizing: border-box;
    background: rgba(255,255,255,.08);
}

.tabs {
    display: flex;
    align-items: center;

    &.single {
        .tab {
            border-bottom-color: transparent;
            cursor: default;
        }
    }
}

.tab {
    flex: 1;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    background: none;
    color: #fff;
    border-bottom: 2px solid transparent;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;

    &.active {
        border-color: #c5e4f3;
        color: #c5e4f3;
    }
}

.submit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.hint {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: rgba(255,255,255,0.64);
    padding: 0 17px;
}
