@keyframes slide {
    from {
        transform: translateX(50px);
        opacity: 0;
    }
    to {
        transform: none;
        opacity: 1;
    }
}

@keyframes load {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}


.notification {
    position: relative;
    width: 320px;
    box-sizing: border-box;
    padding: 16px 40px 16px 16px;
    background: #56596C;
    animation: slide 0.25s;
    transition: opacity 0.25s ease, transform 0.25s ease;
    overflow: hidden;

    @media (max-width: 347px) {
        width: 100%;
    }

    &.hidden {
        opacity: 0;
        transform: translateX(50px);
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: rgba(255, 255, 255, 0.32);
    }

    &.success {
        background: #4ab44a;

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: rgba(13, 182, 121, 0.4);
        }
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        -webkit-backdrop-filter: blur(16px);
        backdrop-filter: blur(16px);
    }
}


.title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    margin: 0 0 2px;

    &:last-child {
        margin-bottom: 0;
    }
}

.message {
    font-size: 12px;
    line-height: 16px;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
        display: block;
    }
}
