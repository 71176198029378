.root {
    padding: 0;
    margin: 0;
    border: none;
    transition: 0.25s ease;
    cursor: pointer;
    height: 44px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 700;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.1;
    }

    &.s {
        height: 24px;
        font-size: 12px;
        padding: 0 12px;
    }

    &.l {
        font-size: 20px;
        height: 56px;
    }

    &.primary {
        background: #c5e4f3;
        color: #050a2e;

        &:focus:not(:disabled),
        &:hover:not(:disabled) {
            background: #b2cedf;
            color: #050a2e;
        }
    }

    &.seconday {
        background: rgba(197,228,243,.16);
        color: #c5e4f3;

        &:focus:not(:disabled),
        &:hover:not(:disabled) {
            background: rgba(197,228,243,.32);
            color: #c5e4f3;
        }
    }
}
