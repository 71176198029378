.list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 12px;
    position: fixed;
    z-index: 10000;
    bottom: 14px;
    right: 14px;

    @media (max-width: 347px) {
        left: 14px;
    }
}
