.app {
    display: flex;
    flex-direction: column;
    padding: 24px;
    min-height: 100vh;
    box-sizing: border-box;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-weight: 700;
    font-size: 24px;
}

.body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
