@import 'normalize.css/normalize.css';

body {
    background: #050b2e;
    background-size: 50%;
    font-family: "PT Root UI", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #fff;
}

button {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
}

:global {
    #root {
        min-height: 100vh;
        background: radial-gradient(50% 100% at 0% 0%,rgba(247,97,42,0.16) 0%,rgba(246,97,42,0) 100%),radial-gradient(50% 100% at 100% 100%,rgba(197,228,243,0.16) 0%,rgba(197,228,243,0) 100%), #050b2e;
    }
}
