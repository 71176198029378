.error {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.code {
    background: rgba(255,255,255,0.08);
    padding: 7px 10px;
    margin: 0;
    overflow: auto;
    font-size: 12px;
    line-height: 1.25;
    word-break: break-all;
    white-space: pre-wrap;

    code {
        word-break: break-all;
    }
}
